import Paginate from '@/components/atoms/Paginate.vue'
import { mapState } from 'vuex'
import { setLinksByContentType } from '@/mixins/main'

export default {
  name: 'UserNotification',
  components: {
    Paginate
  },
  data () {
    return {
      newNotifications: {},
      clearedNotifications: {},
      viewNewNotifications: true
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'userNotification', access: true })
  },
  computed: {
    ...mapState({
      userNotification: state => state.main.userNotification
    })
  },
  mounted () {
    const params = new URLSearchParams({ page_size: 20, is_hidden: 'true' })
    this.getUserNotifications('', params)

    params.set('is_hidden', 'false')
    this.getUserNotifications('', params)
  },
  methods: {
    /** Get user notifications */
    getUserNotifications (sort = '', params = '', link = '') {
      this.tableLoader = true

      if (params) this.params = params

      if (sort) {
        params = this.params
        params.set('ordering', sort)
      }

      if (params && !params.has('is_hidden')) params.set('is_hidden', !this.viewNewNotifications)

      let url = `api/v1/notifications/user_notification/?${params}`
      if (link) url = link

      this.$api.get(url).then(response => {
        this.tableLoader = false
        if (response.code === 200) {
          response.data.results.map(item => {
            item.behavior = {}
            item.sectionUrl = item.content_type === 'protocolfiles' ? '/report/excel' : null
            return item
          })

          if (response.data.results[0].is_hidden) this.clearedNotifications = response.data
          else this.newNotifications = response.data
        }
      })
    },

    changePage (link) {
      this.getUserNotifications('', '', link)
    },

    /** Move notification to cleared list */
    readNotification (record) {
      const body = {
        is_hidden: true
      }
      this.$api.patch(`api/v1/notifications/user_notification/${record.id}/`, { body }).then(response => {
        if ([200, 201].includes(response.code)) {
          this.$notification.success('moveNotification')
          this.newNotifications.results = this.newNotifications.results.filter(item => item.id !== record.id)
          this.clearedNotifications.results.push(record)
          this.$store.commit('decrementUserNotification', 'unchecked_notification')
        }
      })
    },

    openDetails (item) {
      this.$router.push({
        name: setLinksByContentType(item),
        params: {
          id: item.sailor_id,
          documentID: item.is_service_record || item.object_id,
          lineID: item.object_id
        }
      })
    }
  }
}
